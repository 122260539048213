<template>
  <div class="flex flex-col gap-1">
    <BackButton class="mb-1" :route="{ name: 'Director'}" />
    <ArrowButton
      text="clientes actuales"
      background="#cccccc"
      color="#000000"
      @onClick="$router.push({ name: 'ClientesActuales' })"
    />
    <ArrowButton
      text="ingresar nuevo"
      background="#cccccc"
      color="#000000"
      @onClick="$router.push({ name: 'IngresarNuevo' })"
    />
  </div>
</template>

<script>
import BackButton from '@/components/shared/BackButton.vue'
import ArrowButton from '@/components/shared/ArrowButton.vue'
export default {
  name: 'IngresarCliente',
  components: {
    BackButton,
    ArrowButton
  }
}
</script>
